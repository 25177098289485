<template>
  <v-card
    outlined
    class="mb-2 px-2 d-flex flex-column"
    max-height="250"
    height="250"
    width="100%"
  >
    <v-card-title class="title text-body-1 font-weight-medium pb-2 mb-0">
      {{ formatModuleName(service.name) }}
    </v-card-title>
    <v-card-text class="my-0 pb-0 text-body-2">
      <p
        v-if="service.description"
        class="text-left grey--text text--darken-1 mt-2"
      >
        {{ service.description }}
      </p>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="pl-4 font-weight-bold">
      <span>{{ formatMoney(service.price) }}</span>
      <v-spacer />

      <v-btn
        rounded
        right
        small
        class="text-none text-subtitle-2 py-3 px-4 elevation-0"
        color="accent"
        :disabled="disabled"
        @click="setServiceToCart(service)"
      >
        {{ disabled ? "Adicionado" : "Adicionar" }}
      </v-btn>
    </v-card-actions>
    <div class="">
      <div class="d-flex align-center mb-4"></div>
    </div>
  </v-card>
</template>
<script>
import { formatModuleName, formatMoney } from "@/helpers/services/utils";
export default {
  name: "ServiceCard",
  props: {
    service: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
    formatModuleName,
    formatMoney,

    setServiceToCart(service) {
      this.$emit("action", service);
    },
  },
};
</script>
<style scoped></style>
