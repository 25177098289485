<template>
  <v-row
    fluid
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
    class="px-5 py-2 ma-0 d-flex align-center"
  >
    <v-col
      class="d-flex align-center ma-0 pa-0"
      cols="12"
      sm="12"
      :md="customRight ? 9 : 12"
      :lg="customRight ? 8 : 12"
    >
      <v-btn
        @click="goBack"
        :class="`${
          $vuetify.breakpoint.mobile ? 'mr-2 ml-0' : ''
        } breadcrumb-link`"
        icon
        :small="$vuetify.breakpoint.mobile"
      >
        <v-icon v-text="'mdi-arrow-left'" />
      </v-btn>

      <v-row class="ma-0 pa-0" width="250" v-if="loading">
        <v-col
          class="ma-0 pa-0 d-flex justify-start"
          v-for="i in 2"
          :key="i"
          md="2"
        >
          <v-skeleton-loader type="chip"></v-skeleton-loader>
          <v-icon v-if="i < 2" v-text="'mdi-chevron-right'" />
        </v-col>
      </v-row>

      <v-breadcrumbs
        v-else-if="showPreviousPages"
        :items="localBreadcrumbs"
        class="pa-0 ml-3 mr-2 breadcrumbs breadcrumb-link"
        large
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <MenuClientSearch
        v-if="showPreviousPages"
        :client-name="clientName"
        :loading="localLoading"
        :currentPage="currentPage"
        @reload="$emit('reload')"
        :small="$vuetify.breakpoint.mobile"
      />

      <v-icon
        v-if="currentPage && showPreviousPages"
        class="ml-4"
        v-text="'mdi-chevron-right'"
      />

      <v-breadcrumbs
        v-if="!loading && currentPage"
        :items="updatedCurrentPage"
        class="pa-0 ml-3 mr-2 breadcrumbs breadcrumb-link"
        large
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>

    <v-col
      v-if="customRight"
      class="ma-0 pa-0 d-flex"
      cols="12"
      sm="12"
      :md="customRight ? 3 : 0"
      :lg="customRight ? 4 : 0"
    >
      <slot name="right" />
    </v-col>
  </v-row>
</template>
<script>
import MenuClientSearch from "@/components/reseller/buttons/MenuClientSearch";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ClientBreadcrumb",

  props: {
    loading: { type: Boolean, default: false },
    currentPage: { type: String, default: "" },
    customRight: { type: Boolean, default: false },
  },

  components: { MenuClientSearch },

  data() {
    return {
      localLoading: false,
      localBreadcrumbs: [
        {
          text: "Clientes",
          disabled: false,
          to: "/reseller/clients",
        },
        {
          text: "",
          disabled: false,
          to: "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "clients",
      "selectedResellerClient",
      "getSelectedResellerClientError",
    ]),

    updatedCurrentPage() {
      return [
        {
          text: this.currentPage,
          disabled: false,
        },
      ];
    },

    clientName() {
      return this.selectedResellerClient
        ? this.selectedResellerClient.name
        : "";
    },

    allClients() {
      return this.clients.map((item) => {
        return {
          name: item.name,
          main_domain: item.main_domain,
          key: item.key,
        };
      });
    },

    showPreviousPages() {
      return !this.loading && this.$vuetify.breakpoint.smAndUp;
    },
  },

  methods: {
    ...mapActions(["getSelectedResellerClient", "getClients"]),

    goBack() {
      if (this.currentPage) {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: "/reseller/clients" });
      }
    },
  },

  async beforeMount() {
    this.localLoading = true;
    await this.getSelectedResellerClient(this.$route.query.key).then(() => {
      if (this.getSelectedResellerClientError) {
        this.goBack();
      }
    });
    this.localLoading = false;
  },
};
</script>
