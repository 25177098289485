<template>
  <v-container :class="$vuetify.breakpoint.smAndUp ? 'px-6' : ''">
    <v-card outlined class="my-2" max-height="100%">
      <ClientBreadcrumb current-page="Serviços" />

      <ServicePurchase
        :outlined="false"
        @action="buyServices"
        hasAction
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { successMessages } from "@/helpers/variables/snackbarMessages";
import ServicePurchase from "@/components/purchase/ServicePurchase";
import ClientBreadcrumb from "@/components/reseller/ClientBreadcrumb.vue";

export default {
  name: "NewService",
  components: { ServicePurchase, ClientBreadcrumb },

  data: () => ({
    serviceShoppingCart: [],

    moneyOptions: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      precision: 2,
    },
    loading: false,
    breadcrumbs: [
      {
        text: "Clientes",
        disabled: false,
        to: "/reseller/clients",
      },
      {
        text: "",
        disabled: false,
        to: "",
      },
    ],
  }),

  methods: {
    ...mapActions(["getPlans"]),
    ...mapMutations(["setSnackBar", "clearCart"]),

    buyServices(payload) {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/resellers/services`;
      const auth = { headers: { Authorization: this.token } };

      payload["client_key"] = this.$route.query.key;

      this.$axios
        .post(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            show: true,
            message: successMessages["operation_sucess"],
          });
          this.$router.back();
        })
        .catch((error) => {
          console.error(error);
          this.setSnackBar({
            show: true,
            is_key: true,
            message_key: error.response.data.code,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["services", "contentWidth", "token"]),
  },

  async beforeMount() {
    this.clearCart();
    if (!this.services.length) {
      this.loading = true;
      await this.getPlans();
      this.loading = false;
    }
  },
};
</script>
