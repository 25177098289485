<template>
  <v-card
    class="pa-0 overflow-y-auto"
    flat
    tile
    :loading="loading"
    :disabled="loading"
  >
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        xl="4"
        v-for="service in services"
        :key="service.key"
      >
        <ServiceCard
          :disabled="isSelected(service.key)"
          :service="service"
          @action="$emit('add', service)"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import ServiceCard from "@/components/reseller/cards/ServiceCard";

export default {
  name: "ServiceSelector",

  props: {
    loading: { type: Boolean, default: false },
  },

  components: { ServiceCard },

  computed: {
    ...mapGetters(["services", "servicesCartKeys"]),
  },

  methods: {
    isSelected(key) {
      return this.servicesCartKeys.includes(key);
    },
  },
};
</script>
